<template>
  <el-row>
    <el-col class="shopping-info-container">
      <el-col class="consignee-info-title container">
        <el-col class="title">收货人信息</el-col>
        <el-col class="add-consignee">新增收货人信息</el-col>
      </el-col>
      <el-col class="address-list container">
        <el-collapse>
          <el-collapse-item>
            <template slot="title">
              <el-col class="default-address">
                <el-col class="cur-icon icon-jiantou">
                  <img src="@/img/common/icon/ic_jiantou.svg">
                </el-col>
                <el-col class="cur-icon icon-checked">
                  <img src="@/img/common/icon/ic_checked.svg">
                </el-col>
                <el-col class="name content">尼古拉斯</el-col>
                <el-col class="address content">广东 深圳市 福田区 华强街道 国际创新中心 A座 201 110****1201</el-col>
                <el-col class="default-btn">默认地址</el-col>
                <el-col class="right">
                  <el-col class="edit content" @click.stop.prevent="() => {}" @click.native.prevent="editAddress">编辑</el-col>
                  <el-col class="delete content">删除</el-col>
                </el-col>
              </el-col>
            </template>
            <el-col class="history-address-list">
              <el-col class="item">
                <el-col class="name content">尼古拉斯</el-col>
                <el-col class="address content">广东 深圳市 福田区 华强街道 国际创新中心 A座 201 110****1201</el-col>
                <el-col class="right">
                  <el-col class="set-default content">设为默认</el-col>
                  <el-col class="edit content" @click.native.prevent="editAddress">编辑</el-col>
                  <el-col class="delete content">删除</el-col>
                </el-col>
              </el-col>
              <el-col class="item">
                <el-col class="name content">尼古拉斯</el-col>
                <el-col class="address content">广东 深圳市 福田区 华强街道 国际创新中心 A座 201 110****1201</el-col>
                <el-col class="right">
                  <el-col class="set-default content">设为默认</el-col>
                  <el-col class="edit content" @click.native.prevent="editAddress">编辑</el-col>
                  <el-col class="delete content">删除</el-col>
                </el-col>
              </el-col>
              <el-col class="item">
                <el-col class="name content">尼古拉斯</el-col>
                <el-col class="address content">广东 深圳市 福田区 华强街道 国际创新中心 A座 201 110****1201</el-col>
                <el-col class="right">
                  <el-col class="set-default content">设为默认</el-col>
                  <el-col class="edit content" @click.native.prevent="editAddress">编辑</el-col>
                  <el-col class="delete content">删除</el-col>
                </el-col>
              </el-col>
            </el-col>
          </el-collapse-item>
        </el-collapse>
      </el-col>
      <el-col class="product-title container">
        <el-col class="product-name title">商品</el-col>
        <el-col class="product-spec title">规格</el-col>
        <el-col class="product-price title">单价</el-col>
        <el-col class="product-num title">数量</el-col>
        <el-col class="product-count title">合计</el-col>
      </el-col>
      <el-col class="product-info-list">
        <el-col class="product-info item container" v-for="(item,idx) in shoppingInfo" :key="idx">
          <el-col class="img">
            <img :src="item.goodsImageUrl"/>
          </el-col>
          <el-col class="product-name">
            <el-col>{{item.softwareGoodsName}}</el-col>
          </el-col>
          <el-col class="product-spec">
            {{item.specsName}}
          </el-col>
          <el-col class="product-price">
            {{item.goodsPrice}}
          </el-col>
          <el-col class="product-num">{{ item.goodsNum }}</el-col>
          <el-col class="product-price-count product-price">¥{{ (item.goodsNum*item.goodsPrice).toFixed(2) }}</el-col>
        </el-col>
      </el-col>
      <el-col class="pay-container container">
        <el-col class="settlement-info">
          <el-col class="product-count">共&nbsp;<span class="product-num">{{ numTotal }}</span>&nbsp;件商品</el-col>
          <el-col class="settlement-price">总价：</el-col>
          <el-col class="price">￥{{ priceTotal }}</el-col>
          <el-col class="payment-btn">
            <el-button>立即支付</el-button>
          </el-col>
        </el-col>
      </el-col>
    </el-col>
  </el-row>
</template>

<script>

import api from '@/api/mallApi'

export default {
  name: "shoppingInfo",
  data() {
    return {
      shoppingInfo: [
        {
          'softwareGoodsId': '154760da-f67f-42cc-b628-a2b1bdaa598c',
          'classifyBrandId': 'a414da40-950f-4f2f-b989-7b23c8a2874f',
          'classifyFunctionId': 'cab6b6d8-1e50-4572-b332-9d6d166b2ed9',
          'classifyIndustryId': '3e0361b8-41b0-4d20-9e1c-bd58593b90ac',
          'softwareGoodsType': '2',
          'softwareGoodsName': 'Surface Book3',
          'specsName': 'Surface Laptop Go i5/8/128 Commercial Ice Blue 12',
          'goodsPrice': '5475.84',
          'goodsNum': 2,
          'goodsImageUrl': 'https://mall.img.edensoft.com.cn/20210617/16239281988181072.png'
        },
        {
          'softwareGoodsId': '4604d20a-a0a2-4e1b-9acd-b4be4004e7b4',
          'classifyBrandId': 'a414da40-950f-4f2f-b989-7b23c8a2874f',
          'classifyFunctionId': 'cab6b6d8-1e50-4572-b332-9d6d166b2ed9',
          'classifyIndustryId': '3e0361b8-41b0-4d20-9e1c-bd58593b90ac',
          'softwareGoodsType': '2',
          'softwareGoodsName': 'Surface Laptop Go',
          'specsName': 'Surface Book3 13ini7/16/256GC Commercial Platinum',
          'goodsPrice': '15199.2',
          'goodsNum': 3,
          'goodsImageUrl': 'https://mall.img.edensoft.com.cn/20210617/16239281110007919.png'
        }
      ],
      priceTotal: 0,
      numTotal: 0,
      addressList: [],
      defaultList: {}
    }
  },
  mounted() {
    // this.shoppingInfo = this.$route.params.shopping
    this.totalPrice();
    // this.getAddressList();
  },
  methods: {
    getAddressList() {
      // let obj = this;
      api.getAddressList({}).then((res) => {
        if(res.code == 200) {
          return true;
        }
      });

    },
    editAddress() {
      console.log(1);
    },
    totalPrice() {
      let obj = this;
      let price = 0;
      let num = 0;
      obj.shoppingInfo.forEach(item => {
        price = price + Number((item.goodsPrice * item.goodsNum).toFixed(2))
        num = num + item.goodsNum
      });
      obj.priceTotal = price;
      obj.numTotal = num;
    }
  }
}
</script>

<style lang="less" scoped>
  .shopping-info-container {
    width: 1200px;
    margin: auto;
    float: unset;
    height: auto;
    .container {
      background: #FFFFFF;
    }
    .consignee-info-title {
      height: 60px;
      margin: 24px 0 8px 0;
      padding: 20px 38px;
      .title {
        font-size: 16px;
        font-weight: bold;
        color: #414141;
        width: auto;
      }
      .add-consignee {
        font-size: 14px;
        color: #D60010;
        width: auto;
        float: right;
      }
    }
    .address-list {
      padding: 26px 37px;
      height: auto;
      margin-bottom: 8px;

      /deep/ .el-collapse-item__header {
        border-bottom: 0;
      }

      /deep/ .el-collapse {
        border-top: 0;
      }

      /deep/ .el-collapse-item__wrap {
        border-bottom: 0;
      }

      /deep/ .el-collapse-item__arrow {
        display: none;
      }

      /deep/ .el-collapse-item__header {
        height: 24px;
        line-height: 24px;
      }

      .default-address {
        height: 24px;
        vertical-align: top;
        div {
          height: 24px;
          vertical-align: top;
        }
        .cur-icon {
          width: auto;
          img {
            vertical-align: middle;
          }
        }
        .icon-checked {
          margin-left: 27px;
        }

        .name {
          margin-left: 18px;
        }


        .default-btn {
          margin-left: 17px;
          width: 71px;
          background: #D60010;
          border-radius: 4px;
          vertical-align: middle;
          height: 24px;
          padding: 0 7px;
          font-size: 14px;
          color: #FFFFFF;
        }
      }

      .delete:hover {
        cursor: pointer;
      }

      .delete {
        margin-left: 28px;
      }

      .edit:hover {
        cursor: pointer;
      }

      .edit {
        margin-left: 28px;
      }

      .right {
        float: right;
        width: auto;
      }

      .content {
        font-size: 14px;
        color: #414141;
        width: auto;
      }

      .address {
        margin-left: 31px;
      }

      .history-address-list {
        padding-left: 75px;

        .item {
          margin-top: 38px;
        }

        .set-default:hover {
          cursor: pointer;
          color: #D60010;
        }
      }
    }
    .product-title {
      height: 60px;
      margin-bottom: 8px;
      padding: 20px 51px;

      .title {
        font-size: 16px;
        color: #414141;
        width: auto;
      }
      .product-spec {
        margin-left: 441px;
      }
      .product-price {
        margin-left: 216px;
      }
      .product-num {
        margin-left: 126px;
      }
      .product-count {
        margin-left: 142px;
      }
    }
    .product-info-list {
      .product-info {
        margin-bottom: 8px;
        height: 160px;
        padding: 30px 40px;

        .img {
          width: 100px;
          height: 100px;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .product-name {
          font-size: 16px;
          font-weight: bold;
          color: #414141;
          width: 156px;
          margin: 27px;
        }

        .product-spec {
          font-size: 16px;
          color: #414141;
          width: 314px;
          margin: 27px;
        }
        .product-price {
          font-size: 16px;
          color: #414141;
          margin: 38px;
          width: auto;
        }
        .product-num {
          font-size: 14px;
          font-weight: bold;
          color: #414141;
          width: auto;
          margin: 38px 0 0 80px;
        }
        .product-price-count {
          margin-left: 114px;
          margin-right: 0;
        }
      }
    }
    .pay-container {
      height: 80px;
      margin-bottom: 88px;
      padding: 13px 19px;
      .settlement-info {
        float: right;
        width: auto;
        .product-count {
          font-size: 14px;
          color: #414141;
          width: auto;
          line-height: 54px;
          margin-right: 28px;
          .product-num {
            color: rgba(245, 110, 101, 1);
          }
        }
        .settlement-price {
          font-size: 14px;
          color: #414141;
          width: auto;
          line-height: 54px;
        }
        .price {
          font-size: 24px;
          font-weight: bold;
          color: #D60010;
          width: auto;
          line-height: 54px;
        }

        .payment-btn {
          width: auto;
          .el-button {
            width: 194px;
            height: 54px;
            background: #D60010;
            border-radius: 27px;
            font-size: 18px;
            font-weight: bold;
            color: #FFFFFF;
            margin-left: 28px;
          }

          .el-button:active {
            border-color: unset;
          }
        }

      }
    }
  }


</style>
